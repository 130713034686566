import firebase from "firebase/app";

require("firebase/firestore");
require("firebase/storage");
require("firebase/auth");

const firebaseConfig = {
  apiKey: "AIzaSyCD4kDdOeV4jIFYs552_9NmIaFSHpaekZ8",
  authDomain: "thinkerspace-a5ca6.firebaseapp.com",
  databaseURL: "https://thinkerspace-a5ca6.firebaseio.com",
  projectId: "thinkerspace-a5ca6",
  storageBucket: "thinkerspace-a5ca6.appspot.com",
  messagingSenderId: "208077099467",
  appId: "1:208077099467:web:a976c8864b570ff576264e",
  measurementId: "G-768Q57XLE8"
};
firebase.initializeApp(firebaseConfig);

const firestore = firebase.firestore();
// const storage = firebase.app().storage('gs://uploadmystock-staging-uploads')

// const PROJECT_ID = 'uploadmystock-staging'
// const STRIPE_ID = 'pk_test_Do2tn3cwJGOokUNODikAEbKL' //rename-file PROJECT_ID, STRIPE_ID
export { firebase, firestore };
