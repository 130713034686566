export interface ClassItem {
  title: string;
  priceUnit: string;
  startDateText: string;
  price: number;
  iterations: number[];
  unit: string;
  priceId: string;
  terms?: string[];
  action: string;
  startDate: string;
  location: string;
}

export const classList: ClassItem[] = [
  {
    title: "Mystery Room",
    price: 20,
    priceUnit: "1 hr, 15 mins",
    unit: "week",
    action: "Enroll",
    location: "Highland Community Center 14224 NE Bel-Red Rd, 98007 (tentative)",
    startDate: "2021/09/16", // Should be in YYYY/MM/DD
    startDateText: "Sept 16 2021 - May 12 2022 (excluding holidays)",
    iterations: [9, 1, -1, 2, -3, 7, -1, 6, -1, 4 ],
    priceId: "price_1IuqqSI64tAA60xFUq25QcDx",
  },
  {
    title: "Mystery Room (for continuing students)",
    price: 15,
    priceUnit: "1 hr",
    unit: "week",
    action: "Enroll",
    location: "Online",
    startDate: "2021/06/16", // Should be in YYYY/MM/DD
    startDateText: "Jun 16 2021 - July 14 2021",
    iterations: [5],
    priceId: "price_1H1gDDI64tAA60xFrtYq1a1z",
  },
  {
    title: "Great American Road Trip",
    price: 15,
    priceUnit: "1 hr",
    unit: "week",
    action: "Enroll",
    location: "Online",
    startDate: "2021/09/16", // Should be in YYYY/MM/DD
    startDateText: "Sept. 16, 2020 - May 12, 2021 (excluding holidays)",
    iterations: [9, 1, -1, 2, -3, 7, -1, 6, -1, 4 ],
    priceId: "price_1H1gDZI64tAA60xFkjeqhqrV",
  }, 
  {
    title: "Novelist's Collective",
    price: 20,
    priceUnit: "1 hr, 30 mins",
    unit: "week",
    action: "Enroll",
    location: "Online",
    startDate: "2021/09/16", // Should be in YYYY/MM/DD
    startDateText: "Sept. 16, 2020 - May 12, 2021 (excluding holidays)",
    iterations: [9, 1, -1, 2, -3, 7, -1, 6, -1, 4 ],
    priceId: "price_1IuqsrI64tAA60xFB6PV9V4F",
  },
  {
    title: "Sail Around the World Adventure",
    price: 15,
    priceUnit: "1 hr",
    unit: "week",
    action: "Enroll",
    location: "Online",
    startDate: "2021/09/16", // Should be in YYYY/MM/DD
    startDateText: "Sept 16 2021 - May 12 2022 (excluding holidays)",
    iterations: [9, 1, -1, 2, -3, 7, -1, 6, -1, 4 ],
    priceId: "price_1H1gDpI64tAA60xFUmgWHjhc",
  },

];
