import "./index.scss";

import React, { useEffect, useState } from "react";
import { firebase, firestore } from "../firebase";

import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";

function AdminPanel() {
  const [subscriptions, setSubscriptions] = useState([]);

  useEffect(() => {
    const docs: any = [];
    firestore
      .collection("subscriptions")
      .get()
      .then((snap) => {
        snap.forEach((doc) => {
          docs.push({
            id: doc.id,
            ...doc.data(),
          });
        });
        console.log(docs);
        setSubscriptions(docs);
      });
  }, []);

  return (
    <div>
      <Typography component="h2" variant="h2" gutterBottom align="center">
        Subscription Data
      </Typography>

      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>charge </TableCell>
              <TableCell>className </TableCell>
              <TableCell>classStartDate </TableCell>
              <TableCell>email </TableCell>
              <TableCell>firstName </TableCell>
              <TableCell>lastName </TableCell>
              <TableCell>notes </TableCell>
              <TableCell>Filled form on </TableCell>
              <TableCell>phone </TableCell>
              <TableCell>Children </TableCell>
              <TableCell>Payment Scheduled</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {subscriptions.map((subscription: any) => (
              <TableRow key={subscription.id}>
                <TableCell> {subscription.charge} </TableCell>
                <TableCell> {subscription.className} </TableCell>
                <TableCell> {subscription.classStartDate} </TableCell>
                <TableCell> {subscription.email} </TableCell>
                <TableCell> {subscription.firstName} </TableCell>
                <TableCell> {subscription.lastName} </TableCell>
                <TableCell> {subscription.notes} </TableCell>
                <TableCell>
                  {subscription.paymentDate &&
                    new Date(
                      subscription.paymentDate.nanoseconds * 1000
                    ).toDateString()}
                </TableCell>
                <TableCell> {subscription.phone} </TableCell>
                <TableCell className="child-row">
                  {JSON.stringify(subscription.children, null, 2)}
                </TableCell>
                <TableCell> {subscription.scheduled ? "✓" : "NO"} </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default AdminPanel;
