import "./App.scss";

import { Route, BrowserRouter as Router, Switch } from "react-router-dom";

import AdminPanel from "./AdminPanel";
import Auth from "./Auth";
import Classes from "./Classes";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import MenuAppBar from "./AppBar";
import PaymentSuccess from "./PaymentSuccess";
import Portal from "./Portal";
import React from "react";

function App() {
  // useEffect(() => {
  //   firebase.auth().onAuthStateChanged((authedUser: firebase.User | null) => {
  //     if (authedUser) {
  //       firestore
  //         .collection("users")
  //         .doc(authedUser.uid)
  //         .get()
  //         .then((snap) => {
  //           setUser(snap.data());
  //           setLoading(false);
  //         });
  //     }
  //   });
  // }, []);
  return (
    <>
      <CssBaseline />
      <MenuAppBar />
      {/* {loading && (
          <div className="loader">
            <CircularProgress color="secondary" />
          </div>
        )}
        {user ? <Dashboard user={user} /> : <Auth />} */}
      <Router>
        <Switch>
          <Route path="/success">
            <Container className="App">
              <PaymentSuccess />{" "}
            </Container>
          </Route>
          <Route path="/auth">
            <Container className="App">
              <Auth />
            </Container>
          </Route>
          <Route path="/admin">
            <Container className="App" disableGutters maxWidth={"xl"}>
              <AdminPanel />{" "}
            </Container>
          </Route>
          <Route path="/portal">
            <Container className="App" disableGutters maxWidth={"xl"}>
              <Portal />
            </Container>
          </Route>
          <Route path="/">
            <Classes />
          </Route>
        </Switch>
      </Router>
    </>
  );
}

export default App;
