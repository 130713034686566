import "./index.scss";

import React, { useEffect, useState } from "react";

import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { CircularProgress } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import axios from "axios";

const queryString = require("query-string");
const HOST = "https://us-central1-thinkerspace-a5ca6.cloudfunctions.net";

export default function PaymentSuccess() {
  const parsed = queryString.parse(window.location.search);
  const [email, setEmail] = useState<string>("");
  const [error, setError] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);

  const redirectToPortal = async () => {
    setError(null);
    setLoading(true);
    try {
      const response = await axios.post(`${HOST}/createPortalLink`, {
        email: email.trim(),
      });
      window.location.href = response.data.url;
    } catch (e) {
      setError(e.toString());
    }
    setLoading(false);
  };

  useEffect(() => {
    setEmail(parsed.email);
  });

  return (
    <div className="classes">
      <br />
      <br />
      <Card>
        <CardContent>
          <Typography variant="h4" gutterBottom align="center">
            Payment Management
          </Typography>
          <br />
          <br />

          <div className="card">
            <TextField
              required
              id="standard-required"
              label="Registered Email"
              defaultValue={parsed.email}
              onBlur={(e) => setEmail(e.target.value)}
              helperText={error}
              error={!!error}
            />
            <br />
            <Button
              disabled={loading}
              variant="contained"
              color="primary"
              onClick={redirectToPortal}
            >
              Submit
              {loading && <CircularProgress />}
            </Button>
          </div>
        </CardContent>
      </Card>
    </div>
  );
}
