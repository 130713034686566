import "./index.scss";

import { Button, CircularProgress, TextField } from "@material-ui/core";
import React, { Dispatch, SetStateAction, useState } from "react";

import CardContent from "@material-ui/core/CardContent";
import { ClassItem } from "../classList";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import axios from "axios";
import { firestore } from "../firebase";
import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe("pk_live_FedXn2Xv5BSxcAOSJB7qyUfg004gVP5NbC");
// const stripePromise = loadStripe("pk_test_DAaqQAahn0bYTU1to4NaKaD8000lUsraTc");
const HOST = "https://us-central1-thinkerspace-a5ca6.cloudfunctions.net";

interface Props {
  index: number;
  selectedIndex: number;
  classItem: ClassItem;
  setOpenpanel: Dispatch<SetStateAction<number>>;
}

// interface ChildType {
//   firstName: string;
//   lastName: string;
// }

interface PaymentObject {
  firstName: string;
  lastName: string;
  email: string;
  notes: string;
  phone?: string;
  children: any[];
}

const DEFAULT_CHILD = { firstName: "", lastName: "" };

export default function ClassCard({
  classItem,
  selectedIndex,
  index,
  setOpenpanel,
}: Props) {
  const [finalizing, setFinalizing] = useState(false);
  const [state, setState] = useState<PaymentObject>({
    children: [DEFAULT_CHILD],
    firstName: "",
    lastName: "",
    email: "",
    notes: "",
  });

  const addChildren = () => {
    setState((prevState: PaymentObject) => ({
      ...prevState,
      children: [...prevState.children, { ...DEFAULT_CHILD }],
    }));
  };

  const setChildParams = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number,
    key: string
  ) => {
    e.persist();
    setState((prevState: PaymentObject) => {
      const state = { ...prevState };
      state.children[index][key] = e.target && e.target.value;
      return { ...state };
    });
  };

  const setParentParams = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    key: keyof PaymentObject
  ) => {
    e.persist();
    setState((prevState: PaymentObject) => {
      const state = { ...prevState };
      if (key !== "children" && e.target) {
        state[key] = e.target.value;
      }
      return { ...state };
    });
  };

  const purchase = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setFinalizing(true);
    const doc = firestore.collection("subscriptions").doc();
    doc
      .set({ ...state, charge: classItem.price * state.children.length })
      .then(async () => {
        const response = await axios.post(`${HOST}/createStripeSession`, {
          email: state.email,
          priceId: classItem.priceId,
          className: classItem.title,
          startDate: classItem.startDate,
          iterations: classItem.iterations,
          quantity: state.children.length,
          subscriptionDoc: doc.id,
        });
        const stripe = await stripePromise;
        if (stripe) {
          await stripe.redirectToCheckout({
            sessionId: response.data,
          });
        }
        setFinalizing(false);
      });
  };

  return (
    <Grid container className="class-card">
      {/* <Grid item xs={12} md={4}>
        <CardMedia className="cover" image={classItem.image} />
      </Grid> */}
      <Grid container className="title-row">
        <Grid item xs={8}>
          <Typography component="h5" variant="h5">
            {classItem.title}
          </Typography>
        </Grid>{" "}
        <Grid item xs={4}>
          <Typography
            className="cost"
            component="h5"
            variant="h6"
            color="primary"
          >
            ${classItem.price} | {classItem.priceUnit}
          </Typography>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={8}>
          <Typography variant="body1" gutterBottom>
            Number of classes:{" "}
            {classItem.iterations.reduce(
              (acc, c) => (c > 0 ? c + acc : acc),
              0
            )}
          </Typography>
          <Typography variant="body1" gutterBottom>
            Start Date: {classItem.startDateText}
          </Typography>
          <Typography variant="body1" gutterBottom>
            Location: {classItem.location}
          </Typography>
        </Grid>
        <Grid item xs={4} className="button-row">
          {selectedIndex !== index && (
            <Button
              onClick={() => setOpenpanel(index)}
              variant="contained"
              color="primary"
              className="enroll-button"
            >
              {classItem.action}
            </Button>
          )}
        </Grid>
      </Grid>

      <ul>
        {classItem.terms &&
          classItem.terms.map((term, i) => (
            <li key={i}>
              <Typography
                variant="body1"
                color="textSecondary"
                gutterBottom
                key={i}
              >
                {term}
              </Typography>
            </li>
          ))}
      </ul>
      <form onSubmit={purchase}>
        {selectedIndex === index && (
          <div>
            <Typography variant="subtitle2">Your Infomation</Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth={true}
                  className="formInput"
                  required
                  label="First Name"
                  onChange={(e) => setParentParams(e, "firstName")}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth={true}
                  className="formInput"
                  required
                  label="Last Name"
                  onChange={(e) => setParentParams(e, "lastName")}
                />
              </Grid>
              <br />
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth={true}
                  className="formInput"
                  required
                  label="E-Mail"
                  type="email"
                  helperText="This is where we will send confirmation emails"
                  onChange={(e) => setParentParams(e, "email")}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth={true}
                  className="formInput"
                  label="Phone"
                  onChange={(e) => setParentParams(e, "phone")}
                />
              </Grid>
            </Grid>
            <br />
            <br />

            <Typography variant="subtitle2">Childs Infomation</Typography>
            {state.children.map((child, index) => (
              <Grid container key={index} spacing={2} className="child-row">
                <Grid item xs={12} md={4}>
                  <TextField
                    fullWidth={true}
                    className="formInput"
                    label="Childs First Name"
                    required
                    onChange={(e) => setChildParams(e, index, "firstName")}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    fullWidth={true}
                    className="formInput"
                    label="Childs Last Name"
                    required
                    onChange={(e) => setChildParams(e, index, "lastName")}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    type="number"
                    fullWidth={true}
                    className="formInput"
                    label="Age"
                    required
                    onChange={(e) => setChildParams(e, index, "age")}
                  />
                </Grid>
                {index > state.children.length - 2 && (
                  <Grid item xs={12} md={4}>
                    <Button
                      className="newChildButton"
                      variant="outlined"
                      color="primary"
                      onClick={addChildren}
                    >
                      Add Another Child
                    </Button>
                  </Grid>
                )}
              </Grid>
            ))}
            <br />
            <br />
            <TextField
              multiline
              fullWidth={true}
              className="formInput"
              label="Additional Notes"
              onChange={(e) => setParentParams(e, "notes")}
            />
            <br />
          </div>
        )}

        <>
          {selectedIndex === index && (
            <>
              <div>
                <br />
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={finalizing}
                >
                  Finalize and pay - ${classItem.price * state.children.length}/
                  {classItem.unit}{" "}
                  {finalizing && (
                    <CircularProgress className="progress" size={30} />
                  )}
                </Button>
                <br />
                <Typography variant="subtitle1">
                  This sets up automatic paments of{" "}
                  <strong>${classItem.price * state.children.length}</strong>{" "}
                  every {classItem.unit} between {classItem.startDateText} for a
                  total of{" "}
                  <strong>
                    {classItem.iterations.reduce(
                      (acc, c) => (c > 0 ? c + acc : acc),
                      0
                    )}{" "}
                    {classItem.unit}s
                  </strong>
                  .
                </Typography>
              </div>
            </>
          )}
        </>
      </form>
    </Grid>
  );
}
