import "./index.scss";

import React, { useEffect, useState } from "react";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { CircularProgress } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { firestore } from "../firebase";

const queryString = require("query-string");

export default function PaymentSuccess() {
  const [payment, setPayment] = useState<any>({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const parsed = queryString.parse(window.location.search);
    firestore
      .collection("subscriptions")
      .doc(parsed.subscription_id)
      .onSnapshot((snap) => {
        const data = snap.data();
        console.log(data);
        if (data) {
          setPayment(data);
          setLoading(false);
        }
      });
  }, []);

  console.log(payment);
  return (
    <div className="classes">
      <br />
      <br />
      <Card>
        <CardContent>
          <Typography variant="h4" gutterBottom align="center">
            Thank You!
          </Typography>

          {/* <img
            src={confirmation}
            alt="Payment confirmed"
            className="confirmation-img"
          /> */}
          <Typography variant="h5" align="center">
            Your payment was sucessfully completed.
          </Typography>
          <Typography variant="body1" align="center">
            We will get in touch with you within the next 24 hours!
          </Typography>

          {loading ? (
            <div className="progress-container">
              <CircularProgress />
              <Typography variant="h5" align="center">
                Finalizing..
              </Typography>
            </div>
          ) : (
            <div></div>
            // <div className="order-summary">
            //   <Typography variant="h6" gutterBottom>
            //     Order Summary
            //   </Typography>
            //   <br />
            //   <Typography color="textSecondary" gutterBottom>
            //     Parent:
            //   </Typography>
            //   <Grid container spacing={2}>
            //     {["firstName", "lastName"].map((value, v) => (
            //       <>
            //         <Grid item xs={6} md={3} key={`a_${v}`}>
            //           <Typography variant="subtitle2">
            //             {camelToTitle(value)}
            //           </Typography>
            //         </Grid>
            //         <Grid item xs={6} md={3} key={`a_${v}`}>
            //           <Typography variant="body1">{payment[value]} </Typography>
            //         </Grid>
            //       </>
            //     ))}
            //   </Grid>
            //   <Grid container spacing={2}>
            //     {["email", "phone"].map((value) => (
            //       <>
            //         <Grid item xs={6} md={3}>
            //           <Typography variant="subtitle2">
            //             {camelToTitle(value)}
            //           </Typography>
            //         </Grid>
            //         <Grid item xs={6} md={3}>
            //           <Typography variant="body1">{payment[value]} </Typography>
            //         </Grid>
            //       </>
            //     ))}
            //   </Grid>
            //   <br />
            //   <Typography color="textSecondary" gutterBottom>
            //     Classes For:
            //   </Typography>

            //   {payment.children.map((child: any, index: number) => (
            //     <Grid container spacing={2}>
            //       <Grid item xs={6} md={3}>
            //         <Typography variant="subtitle2">First Name</Typography>
            //       </Grid>
            //       <Grid item xs={6} md={3}>
            //         <Typography variant="body1">{child.firstName}</Typography>
            //       </Grid>
            //       <Grid item xs={6} md={3}>
            //         <Typography variant="subtitle2">Last Name</Typography>
            //       </Grid>
            //       <Grid item xs={6} md={3}>
            //         <Typography variant="body1">{child.lastName}</Typography>
            //       </Grid>
            //     </Grid>
            //   ))}
            //   <br />
            //   <Grid container spacing={2} justify="flex-end">
            //     <Grid item xs={6} md={3}>
            //       <Typography variant="h6">US$ {payment.charge}</Typography>
            //     </Grid>
            //   </Grid>
            // </div>
          )}
        </CardContent>
      </Card>
    </div>
  );
}
