import "./index.scss";

import React, { useState } from "react";
import { firebase, firestore } from "../firebase";

import Typography from "@material-ui/core/Typography";

const firebaseui = require("firebaseui");

function Auth() {
  const signInSuccessCallBack = (user: any) => {
    firestore
      .collection("users")
      .doc(user.uid)
      .set(user, { merge: true });
  };

  const uiConfig = {
    signInOptions: [
      firebase.auth.EmailAuthProvider.PROVIDER_ID,
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      firebase.auth.FacebookAuthProvider.PROVIDER_ID
    ],
    callbacks: {
      signInSuccessWithAuthResult: (authResult: any) => {
        const user = {
          name: authResult.user.displayName,
          email: authResult.user.email,
          uid: authResult.user.uid,
          photoURL: authResult.user.photoURL
        };
        signInSuccessCallBack(user);
        return false;
      },
      uiShown: () => {
        // setFirebaseuiLoaded(true);
      }
    },
    signInFlow: "popup",
    tosUrl: "/terms",
    credentialHelper: firebaseui.auth.CredentialHelper.NONE,
    privacyPolicyUrl: function() {
      window.location.assign("/privacy");
    }
  };
  const ui =
    firebaseui.auth.AuthUI.getInstance() ||
    new firebaseui.auth.AuthUI(firebase.auth());
  ui.start("#firebaseui-auth-container", uiConfig);

  return (
    <div className="auth">
      <Typography component="h2" variant="h2" gutterBottom align="center">
        Sign in to continue
      </Typography>
      <div id="firebaseui-auth-container" />
    </div>
  );
}

export default Auth;
