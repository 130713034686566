import "./index.scss";

import React, { useState } from "react";

import ClassCard from "../ClassCard";
import Typography from "@material-ui/core/Typography";
import { classList } from "../classList";

export default function Classes() {
  const [selectedClass, setOpenpanel] = useState(-1);

  return (
    <div className="classes">
      <br />
      <Typography className="classes-subtitle" variant="h5">
        Available Classes
      </Typography>
      {classList.map((item, i) => (
        <ClassCard
          key={i}
          classItem={item}
          index={i}
          selectedIndex={selectedClass}
          setOpenpanel={setOpenpanel}
        />
      ))}
      <div id="firebaseui-auth-container" />
    </div>
  );
}
